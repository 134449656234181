<script setup>
import ArrowLine from '~/assets/icons/arrow-line.svg'

const props = defineProps({
    to: {
        type: [String, Object],
        required: false,
        default: '',
    },
    arrow: {
        type: String,
        default: 'fill-darkblue',
    },
    circle: {
        type: String,
        default: 'bg-white',
    },
    text: {
        type: String,
        default: 'text-white',
    },
    size: {
        type: String,
        default: 'normal',
    },
    external: {
        type: Boolean,
        default: false,
    },
    target: {
        type: String,
        default: '',
    },
})

const textSize = ref(null)
const circleSize = ref(null)

switch (props.size) {
case 'small':
    textSize.value = 'text-lg lg:text-base'
        circleSize.value = 'size-10 mr-3'
        break;
case 'normal':
default:
    textSize.value = 'text-xl lg:text-lg'
        circleSize.value = 'size-13 mr-5'
        break;
}

const makeFixedUri = computed(() => {
    if (props.external || props.target === '_blank') {
        return props.to
    }
    const val = fixedUri(props.to)
    return val
})
</script>

<template>
    <NuxtLink
        class="group flex cursor-pointer items-center font-medium uppercase"
        :class="[text, textSize]"
        :to="makeFixedUri"
    >
        <span class="inline-block shrink-0 grow-0 rounded-full text-center" :class="[circle, circleSize]">
            <ArrowLine class="relative top-1/2 inline-block max-w-[42%] -translate-y-1/2 transition-all group-hover:rotate-45" :class="arrow" />
        </span>
        <slot />
    </NuxtLink>
</template>
